@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  src: url('./font-light/font-light.eot') format('embedded-opentype'),
    url('./font-light/font-light.woff') format('woff'),
    url('./font-light/font-light.ttf') format('truetype'),
    url('./font-light/font-light.svg') format('svg');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 400;
  src: url('./font-medium/font-medium.eot') format('embedded-opentype'),
    url('./font-medium/font-medium.woff') format('woff'),
    url('./font-medium/font-medium.ttf') format('truetype'),
    url('./font-medium/font-medium.svg') format('svg');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  src: url('./font-normal/font-normal.eot') format('embedded-opentype'),
    url('./font-normal/font-normal.woff') format('woff'),
    url('./font-normal/font-normal.ttf') format('truetype'),
    url('./font-normal/font-normal.svg') format('svg');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 700;
  src: url('./font-bold/font-bold.eot') format('embedded-opentype'),
    url('./font-bold/font-bold.woff') format('woff'),
    url('./font-bold/font-bold.ttf') format('truetype'),
    url('./font-bold/font-bold.svg') format('svg');
}

@font-face {
  font-family: 'FK Grotesk Neue';
  src: url('./font-light/FKGroteskNeue-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'FK Grotesk Neue';
  src: url('./font-normal/FKGroteskNeue-Regular.eot') format('embedded-opentype'),
    url('./font-normal/FKGroteskNeue-Regular.woff2') format('woff2'),
    url('./font-normal/FKGroteskNeue-Regular.ttf') format('truetype'),
    url('./font-normal/FKGroteskNeue-Regular.svg') format('svg'),
    url('./font-normal/FKGroteskNeue-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'FK Grotesk Neue';
  src: url('./font-medium/FKGroteskNeue-Medium.eot') format('embedded-opentype'),
    url('./font-medium/FKGroteskNeue-Medium.woff2') format('woff2'),
    url('./font-medium/FKGroteskNeue-Medium.ttf') format('truetype'),
    url('./font-medium/FKGroteskNeue-Medium.svg') format('svg'),
    url('./font-medium/FKGroteskNeue-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'FK Grotesk Neue';
  src: url('./font-bold/FKGroteskNeue-Bold.eot') format('embedded-opentype'),
    url('./font-bold/FKGroteskNeue-Bold.woff2') format('woff2'),
    url('./font-bold/FKGroteskNeue-Bold.ttf') format('truetype'),
    url('./font-bold/FKGroteskNeue-Bold.svg') format('svg'),
    url('./font-bold/FKGroteskNeue-Bold.otf') format('opentype');
  font-weight: 700;
}
